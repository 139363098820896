import React from 'react'
import { Link } from 'react-router-dom'
import HeroCarousel from '../components/HeroCarousel'
import VIP from '../components/VIP'
import Chancellors from '../components/vips.json'
import AboutUsSection from '../components/AboutSection'
import LatestNewsMarquee from './LatestNewsMarquee'
import EventsSection from './EventsSection'
import QuickButton from '../components/QuickButton'
import Facilities from '../components/Facilities'
import CTA from '../components/CTA'
import GallerySlide from '../components/GallerySlide'
import { useSelector } from 'react-redux'

function Home() {
  const eventsList =[
    ['Rover Rangers',
    'https://campus.acetians.in/custom/images/rover1.png',
    '',
    '/events/rover-rangers'
    ],
    ['Community Outreach Programs(NSS)',
      'https://campus.acetians.in/custom/images/nss1.png',
      '',
      '/events/nss'
    ],
    ['Green Globe Club',
    'https://campus.acetians.in/custom/images/green-globe.png',
    '',
    '/events/green-globe-club'
    ]
    
    ];
  const store = useSelector((state) => state.auth.store);
  const quicklinks=[
    {
      'name':'Exams',
      'image':'https://university.acetians.in/uploads/icon1.png',
      
    },
    {
      'name':'Admissions',
      'image':'https://university.acetians.in/uploads/icon2.png',
      
    },
    {
      'name':'Results',
      'image':'https://university.acetians.in/uploads/icon3.png',
      
    },
    {
      'name':'Students',
      'image':'https://university.acetians.in/uploads/icon4.png',
      
    },
    {
      'name':'University',
      'image':'https://university.acetians.in/uploads/icon5.png',
      
    },
    {
      'name':'Academics',
      'image':'https://university.acetians.in/uploads/icon6.png',
      
    }
  ] 
  const events = [
    {
      title: "पाँच दिवसीय रोवर्स-रेंजर्स प्रशिक्षण एवं जांच शिविर काआयोजन",
      link: "#",
      image: "#",
      date: "March 11, 2024",
    },
    {
      title: "राष्ट्रीय सेवा योजना के अंतर्गत सप्तदिवसीय विशेष शिविर संबंधित सूचना",
      link: "#",
      image: "#",
      date: "February 19, 2024",
    },
  ];

  const nextEvents = [
    {
      title: "पाँच दिवसीय रोवर्स-रेंजर्स प्रशिक्षण एवं जांच शिविर काआयोजन",
      link: "#",
      date: "March 11, 2024",
    },
    {
      title: "राष्ट्रीय सेवा योजना के अंतर्गत सप्तदिवसीय विशेष शिविर संबंधित सूचना",
      link: "#",
      date: "February 19, 2024",
    },
    {
      title: "Job Fair from Regional Employment Office Gorakhpur",
      link: "#",
      date: "April 22, 2023",
    },
  ];
  return (
    <div>
      <div className='d-flex w-100' style={{maxWidth:'100vw'}}>
         <div className='col-md-12 p-0' style={{padding:0}}>
         <HeroCarousel/>
         </div>
          
      </div>
    <LatestNewsMarquee newsItems={[{
      title: store.marquee,
      link: "#",
      image: "#",
      date: "March 11, 2024",
    },]}/>

    <ul id="quicklinks-ul">
      {quicklinks.map((link,index)=>(<QuickButton key={index} link={link}/>))}
    </ul>
    
    <AboutUsSection
      title="Welcome to"
      subtitle={store.store_title.split("=")[0]}
      description={store.about_page}
      eContentLink="/e-pathshalas/"
      feedbackLink="#"
      imageUrl={store.about_image}   
    /> 
    
    <EventsSection events={events} nextEvents={nextEvents}/>
    <AboutUsSection
      title="PRINCIPAL'S"
      subtitle="MESSAGE"
      description={`Principal’s Message :${store.principal_message}`}
      eContentLink=""
      feedbackLink=""
      imageUrl={`https://campus.acetians.in/custom/images/${store.principal_image}`}
    />
    <Facilities/>
    <div className="container">
      <h1 style={{textAlign:'center'}}>Our College Events</h1>
      <div className="row d-flex">
        {eventsList.map((m)=>(<div className='col-md-4 p-2'>
          <div>
            <div className='card p-3 shadow-sm'>
              <img src={m[1]} style={{width:'100%',height:'270px'}}/>
              <p style={{textAlign:'center',fontWeight:'bolder',fontSize:'20px',marginTop:'10px'}}>{m[0]}</p>
              <Link to={m[3]} className='btn btn-secondary-outlined btn-block'>Visit</Link>
            </div>
          </div>
        </div>))}
      </div>
    </div>
    <CTA/>
    <GallerySlide/>
    </div>
  )
}

export default Home
