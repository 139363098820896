import React from 'react'
import PageHeader from '../components/PageHeader'

import PageContent from '../components/PageContent'
import Blog from '../components/Blog'



function Events(props) {
  const nss=[
`
  <p ><b>Introduction: </b>The National Service Scheme (NSS) is a Central Sector Scheme of Government of India, Ministry of Youth Affairs &amp; Sports. It provides opportunity to the student youth of 11th &amp; 12th Class of schools at +2 Board level and student youth of Technical Institution, Graduate &amp; Post Graduate at colleges and University level of India to take part in various government led community service activities &amp; programmes. The sole aim of the NSS is to provide hands on experience to young students in delivering community service. Since inception of the NSS in the year 1969, the number of students strength increased from 40,000 to over 3.8 million up to the end of March 2018 students in various universities, colleges and Institutions of higher learning have volunteered to take part in various community service programmes. </p>
      <br>

    <p >All the youth volunteers who opt to serve the nation through the NSS led community service wear the NSS badge with pride and a sense of responsibility towards helping needy.
The Konark wheel in the NSS badge having 8 bars signifies the 24 hours of a the day, reminding the wearer to be ready for the service of the nation round the clock i.e. for 24 hours.
Red colour in the badge signifies energy and spirit displayed by the NSS volunteers.
The Blue colour signifies the cosmos of which the NSS is a tiny part, ready to contribute its share for the welfare of the mankind.
</p>
      <br>
    <p ><b>Motto: </b> 
The motto of National Service Scheme is <b> NOT ME BUT YOU </b>
<br>
<b>Benefits of Being a NSS Volunteer:</b>
A NSS volunteer who takes part in the community service programme would either be a college level or a senior secondary level student. Being an active member these student volunteers would have the exposure and experience to be the following:
</p><ul>
    <li>an accomplished social leader</li>
    <li>
     an efficient administrator   
    </li>
    <li>a person who understands human nature</li>
</ul></div>`,
'https://campus.acetians.in/custom/images/nss1.png',
[
   
'https://campus.acetians.in/custom/images/nssimg1.jpg',
'https://campus.acetians.in/custom/images/nssimg2.jpg',
'https://campus.acetians.in/custom/images/nssimg3.jpg',
'https://campus.acetians.in/custom/images/nssimg4.jpg',
'https://campus.acetians.in/custom/images/nssimg5.jpg',
'https://campus.acetians.in/custom/images/nssimg6.jpg',
'https://campus.acetians.in/custom/images/nssimg7.jpg',
'https://campus.acetians.in/custom/images/nssimg8.jpg',
'https://campus.acetians.in/custom/images/nssimg9.jpg',
'https://campus.acetians.in/custom/images/nssimg10.jpg',
'https://campus.acetians.in/custom/images/nssimg11.jpg',
'https://campus.acetians.in/custom/images/nssimg12.jpg',
'https://campus.acetians.in/custom/images/nssimg13.jpg'
]
]; 

const roverRanger=[`
<div class="">
       <p ><b>Introduction: </b>Rovering / Rangering is a way of life. It’s the senior wing of Scout and Guiding. Rovering / Rangering was started by founder of the Scout Movement Lord Baden Powell in 1918. Any regular student of College/ University between the age group of 15 to 25 years can be enlisted as Rover (Boy) / Ranger (Girl). A unit consists of 24 Rovers called Rover Crew and 24 Rangers called Ranger Team. Rovering /Rangering is a uniformed activity. </p>
      <br>

    <p ><b>Membership: </b>A Rover crew is run under the leadership of Rover Scout Leader and Ranger team is run under the leadership of Ranger Leader registered in college. Any regular male student can join Rover crew and female student can join Ranger Team. If member is exceeding 24 in a unit, a new (Rover Crew/Ranger Team) unit can be formed</p>
      <br>

    <p ><b>Rover/Ranger activities: </b>Crew/Team meet to enjoy social activities, fun, adventure, and once-in-a-life-time experiences – with a highly flexible program to suit every individual. A true Rover/Ranger strives to serve the community, make life-long friends and seize every available opportunity. Rovering/Rangering provide various types of training related to the personality development like- National Integration Camps, Skill Development Programmes, Government Initiatives, Swachh Bharat, Ek Bharat Shreshtha Bharat, Disaster Management, Fire Fighting, Messengers of Peace.
 	   </p> 
    </div>
`,
'https://campus.acetians.in/custom/images/rover1.png',
[
'https://campus.acetians.in/custom/images/roverranger.jpeg' ,
'https://campus.acetians.in/custom/images/roverranger2.jpeg' ,
'https://campus.acetians.in/custom/images/roverranger3.jpeg' ,
'https://campus.acetians.in/custom/images/roverranger4.jpeg' ,
'https://campus.acetians.in/custom/images/roverranger5.jpeg' ,
'https://campus.acetians.in/custom/images/roverranger6.jpeg' ,
'https://campus.acetians.in/custom/images/roverranger7.jpeg' ,
'https://campus.acetians.in/custom/images/roverranger8.jpeg' ,
'https://campus.acetians.in/custom/images/roverranger9.jpeg' ,

]
];

const greenGlob=[`
<div class="">
     <p style="">Green Globe Club is an integral part of our college and all the students who join the college
become a member of this club by default.</p>
<h3 style="">Its Purpose or Objectives</h3>
 <p style="">Green Globe Club play an important role in creating environmental <br> awareness in the college
and motivates to be conscious about the environment where we can live and breathe freely.
Few of its main objectives are:</p>
<ul style="">
    <li style="">Encourage Organic method of farming</li>
    <li style="">Organize tree plantation programmes</li>
    <li style="">Promote conservation of water</li>
    <li style="">Switching to renewable energy sources for our energy need</li>
    <li style="">Promote minimum waste generation, source separation of waste and its proper disposal</li>
    <li style="">Spreading awareness for conservation of the biodiversity.</li>
</ul>
<h2 style="">Its Volunteers-</h2>
<p style="">All the students and alumni of the college.</p>
<h2 style="">Activities-</h2>
<ul style="">
    <li style="">Tree Plantation Drive: They have already planted hundreds of trees in college premises and around the college.</li>
</ul>
<p style=""></p>

<ul >
    <li >Tagging Of Plants: They are effectively tagging plants so that students get the idea of its name and identification</li>
</ul>
<p style=""></p>

<ul style="">
    <li style="">Renewable Energy: Installed solar panels for generating electricity.</li>
</ul>
<p style=""></p>

<ul style="">
    <li style="">Water Harvesting System: The college has three units of water harvesting system for water conservation.</li>
</ul>
<p style=""></p>

<ul style="">
    <li style="">Built Bird Houses, Bird Bath &amp; Bird Feeder: They have installed several bird houses to provide a safe shelter for birds to build their nest, a
protective cover from the weather and predators.</li>
</ul>
<p style=""></p>


<ul style="">
    <li style="">Butterfly Garden: They are developing a beautiful butterfly garden to conserve butterflies and help perpetuate
native plant species</li>
</ul>
<p style=""></p>


<ul style="">
    <li style="">Honeybee Farming Initiative: Beekeeping is a low investment &amp; skill industry having the potential to offer direct
employment to lakhs of unemployed youth &amp; farmers</li>
</ul>
<p style=""></p>


    </div>
`,
'https://campus.acetians.in/custom/images/green-globe.png',
[
 'https://campus.acetians.in/custom/images/greenglobe1.jpg' ,
'https://campus.acetians.in/custom/images/greenglobe2.jpg' ,
'https://campus.acetians.in/custom/images/greenglobe3.jpg' ,
'https://campus.acetians.in/custom/images/greenglobe4.jpg' ,
'https://campus.acetians.in/custom/images/greenglobe5.jpg' ,
'https://campus.acetians.in/custom/images/greenglobe6.jpg' ,
'https://campus.acetians.in/custom/images/greenglobe7.jpg' ,
'https://campus.acetians.in/custom/images/greenglobe8.jpg' ,

]
];
const games=[`
<p>
The life of a student is nothing less than a rollercoaster. A student has a lot to manage in terms of assignments, hands-on activities, examinations, internships, workshops, lectures, and much more, all of which are necessary to ensure the best possible academic progress. This is in addition to acquiring new skills and exploring other possibilities. Finding a break to rejuvenate oneself is crucial in the midst of a busy schedule. Extracurricular activities are really important in this situation. These activities help you keep positive, energized, and cheerful while in college and greatly increase your productivity. They not only make you feel better, but they also help in developing your general personality and capacity for social interaction. We strongly believe in the value of extracurricular activities and encourage our students to participate in a variety of them. The school features a variety of sporting facilities to support a well-rounded learning environment. The sport is no longer seen solely as a fitness exercise. It develops discipline, confidence, team spirit, and accountability in addition to physical strength. With this in mind, athletic activities are a priority in our college, with dedicated teams competing in inter-college and intra-college contests. We have always encouraged students to follow their own passions. We have a 400-meter athletic turf track, a football ground, a cricket ground, tennis, basketball, volleyball and badminton facilities.Indoor activities such as table tennis, chess, and carrom are also available on the campus.</p>
`,
'https://campus.acetians.in/custom/images/sport1.jpg',
[
 'https://campus.acetians.in/custom/images/sports1.jpg' ,
'https://campus.acetians.in/custom/images/sports2.jpg' ,
'https://campus.acetians.in/custom/images/sports3.jpg' ,
'https://campus.acetians.in/custom/images/sports4.jpg' ,
'https://campus.acetians.in/custom/images/sports5.jpg' ,
'https://campus.acetians.in/custom/images/sports6.jpg' ,
'https://campus.acetians.in/custom/images/supports7.jpg' ,
'https://campus.acetians.in/custom/images/supports8.jpg' ,
'https://campus.acetians.in/custom/images/supports9.jpg' ,
'https://campus.acetians.in/custom/images/supports10.jpg' ,
'https://campus.acetians.in/custom/images/supports11.jpg' ,
'https://campus.acetians.in/custom/images/supports12.jpg' ,
'https://campus.acetians.in/custom/images/supports13.jpg' ,
'https://campus.acetians.in/custom/images/supports7.jpg' ,
]

];
const images=props.title.includes('Games')?games[2]:props.title.includes('NSS')?nss[2]:props.title.includes('Green')?greenGlob[2]:   roverRanger[2];
  return (
    <div>
      <PageHeader title={props.title} />
      <PageContent elem={<Blog image={props.title.includes('Games')?games[1]:props.title.includes('NSS')?nss[1]:props.title.includes('Green')?greenGlob[1]:   roverRanger[1]} content={props.title.includes('Games')?games[0]:props.title.includes('NSS')?nss[0]:props.title.includes('Green')?greenGlob[0]:   roverRanger[0]}  />}/>
      <div className='container'>
        <div className='row col-md-12  d-flex'>
        {images.map((i)=>(
            <div className='col-md-3'>
                <div style={{padding:'10px'}}>
                 <a href={i} target='_blank'>
                    <img style={{width:'100%',maxHeight:'300px'}} src={i}/>
                 </a>
                
                </div>
            </div>
        ))}
        </div>
      </div>
     
    </div>
  )
}

export default Events
